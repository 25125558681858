<!-- 商品类型 -->
<template>
	<div class="bg-white px-3" style="margin: -20px;margin-top: -1rem; margin-bottom: 0!important;">
		<!-- 头部 搜索 -->
		<button-search class="pt-3" :showSearch="false">
			<!-- 左边按钮 -->
			<template #left>

				<el-button type="success" size="mini" @click="openModel(false)">添加类型</el-button>
				<el-button type="danger" size="mini" @click="deleteAll">批量删除</el-button>
			</template>
		</button-search>

		<!-- 商品列表 -->
		<el-table :data="tableData" style="width: 100%" border class="mt-3" @selection-change="handleSelectionChange">
			<el-table-column type="selection" width="45" align="center">
			</el-table-column>

			<!-- 类型名称 -->
			<el-table-column prop="name" label="类型名称">
			</el-table-column>

			<!-- 属性标签 -->
			<el-table-column label="属性标签" align="center" width="380">
				<template slot-scope="scope">
					{{scope.row.value_list | formatValue}}
				</template>
			</el-table-column>

			<!-- 排序 -->
			<el-table-column prop="order" label="排序" align="center">
			</el-table-column>

			<!-- 状态 -->
			<el-table-column prop="status" label="状态" align="center">
				<template slot-scope="scope">
					<el-button :type="scope.row.status ? 'success' : 'danger'" size="mini" plain @click="changeStatus(scope.row)">{{scope.row.status ? '启用' : '禁用'}}</el-button>
				</template>
			</el-table-column>

			<!-- 操作 -->
			<el-table-column label="操作" align="center" width="150">
				<!-- scope拿到当前位置的索引 -->
				<template slot-scope="scope">
					<el-button-group>
						<el-button type="primary" size="mini" plain @click="openModel(scope)">修改</el-button>
						<el-button type="danger" size="mini" plain @click="deleteItem(scope.row)">删除</el-button>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>
		<!-- 底部内容 -->
		<div style="height: 60px;"></div>
		<el-footer class="border-top d-flex align-items-center px-0 position-fixed bg-white" style="bottom: 0; left: 200px; right: 0;z-index: 100;">
			<!-- 底部分页 -->
			<div style="flex: 1;" class="px-2">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.current"
				 :page-sizes="page.sizes" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper" :total="page.total">
				</el-pagination>
			</div>
		</el-footer>

		<!-- 新增/修改模态框 -->
		<el-dialog title="添加类型" :visible.sync="createModel" top="5vh" width="80%">
			<!-- 表单内容 -->
			<el-form ref="form" :model="form" label-width="80px" :rules="rules">

				<!-- 类型名称 -->
				<el-form-item label="类型名称" prop="name">
					<el-input v-model="form.name" placeholder="类型名称" size="mini" style="width: 25%;"></el-input>
				</el-form-item>

				<!-- 排序 -->
				<el-form-item label="排序">
					<el-input-number v-model="form.order" :min="0" size="mini"></el-input-number>
				</el-form-item>

				<!-- 状态 -->
				<el-form-item label="状态">
					<el-radio-group v-model="form.status" size="mini">
						<el-radio :label="1" border>启用</el-radio>
						<el-radio :label="0" border>禁用</el-radio>
					</el-radio-group>
				</el-form-item>

				<!-- 关联规格 -->
				<el-form-item label="关联规格">
					<div class="d-flex">
						<span class="sku-list-item px-3 py-2 border rounded mr-3" style="line-height: initial;" v-for="(item,index) in form.sku_list" :key="index">
							<font>{{item.name}}</font>
							<i class="el-icon-delete" @click="deleteFormSkuList(index)"></i>
						</span>
						<el-button size="mini" @click="chooseSkus">
							<i class="el-icon-plus"></i>
						</el-button>
					</div>
				</el-form-item>

				<!-- 属性列表 -->
				<el-form-item label="属性列表">
					<el-table :data="value_list" style="width: 100%">
						<!-- 排序 -->
						<el-table-column prop="order" label="排序" width="100">
							<template slot-scope="scope">
								<el-input v-model="scope.row.order" size="mini" placeholder="排序"></el-input>
							</template>
						</el-table-column>

						<!-- 属性名称 -->
						<el-table-column prop="name" label="属性名称" width="180">
							<template slot-scope="scope">
								<el-input v-model="scope.row.name" size="mini" placeholder="属性名称"></el-input>
							</template>
						</el-table-column>

						<!-- 所属类型 -->
						<el-table-column prop="type" label="所属类型" width="160">
							<template slot-scope="scope">
								<el-select v-model="scope.row.type" placeholder="请选择所属类型" size="mini">
									<el-option label="输入框" value="input"></el-option>
									<el-option label="单选框" value="radio"></el-option>
									<el-option label="多选框" value="checkbox"></el-option>
								</el-select>
							</template>
						</el-table-column>

						<!-- 是否显示 -->
						<el-table-column prop="status" label="是否显示" width="100">
							<template slot-scope="scope">
								<el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0"></el-switch>
							</template>
						</el-table-column>

						<!-- 属性值 -->
						<el-table-column label="属性值">
							<template slot-scope="scope" v-if="scope.row.type !== 'input'">
								<el-input type="textarea" v-model="scope.row.default" size="mini" placeholder="一行为一个属性值,多个属性值换行输入" v-if="scope.row.isedit"></el-input>
								<span v-else>{{scope.row.default}}</span>
							</template>
						</el-table-column>

						<!-- 操作 -->
						<el-table-column label="操作" width="180">
							<template slot-scope="scope">
								<el-button type="text" size="mini" @click="editRow(scope)" v-if="scope.row.type !== 'input'">{{scope.row.isedit ? '完成' : '编辑属性值'}}</el-button>
								<el-button type="text" size="mini" @click="delRow(scope.$index)">删除</el-button>
							</template>
						</el-table-column>

					</el-table>
				</el-form-item>

				<!-- 添加一个属性 -->
				<el-form-item>
					<el-button type="text" size="mini" icon="el-icon-plus" @click="addValue">添加一个属性</el-button>
				</el-form-item>
			</el-form>

			<div slot="footer" class="dialog-footer">
				<el-button @click="createModel = false">取 消</el-button>
				<el-button type="primary" @click="submit">确 定</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import buttonSearch from '@/components/common/button-search.vue'; // 引入 按钮、搜索、高级搜索组件
	import common from '@/common/mixins/common.js'; // 引入 全局共公样式
	export default {
		components: { buttonSearch },
		inject: ['layout','app'], // 注入layout.vue
		mixins: [common], // 引入mixins里的common.js
		data() {
			return {
				preUrl:"goods_type", //api
				
				tableData:[],
	
				multipleSelection: [],
				currentPage: 1,
				createModel: false,
				editIndex: -1,

				form: {
					name: "",
					order: 50,
					status: 1,
					sku_list: [],
				},
				value_list: [],
				// 表单验证规则
				rules: {
					name: [{
						required: true,
						message: '类型名称不能为空',
						trigger: 'blur',
					}],
				}
			}
		},
		
		// 计算属性
		computed:{
			// 关联规格id组成的一维数组
			skus_id(){
				return this.form.sku_list.map(item=>item.id)
			}
		},
		// 过滤数据
		filters: {
			formatValue(value) {
				let arr = value.map(v => v.name)
				return arr.join(',');
			}
		},
		// 生命周期监听页面
		methods: {
			// 获取列表 可修改
			getListResult(e){
				this.tableData = e.list.map(item=>{
					item.value_list = item.goods_type_values
					item.sku_list = item.skus
					return item
				})
			},
			
			// 批量删除url 自定义 针对不同链接形式
			// deleteAllUrl(){
			// 	return '输入定制的url'
			// },
			
			// 打开模态框
			openModel(e = false) {
				// 增加新规格
				if (!e) {
					// 初始化表单
					this.form = {
						name: "",
						order: 50,
						status: 1,
						sku_list: [],
					}
					this.value_list = []
					this.editIndex = -1
				} else {
					// 修改规格
					this.form = {
						...e.row //e.row=整个tableData对象
					}
					this.value_list = [...e.row.value_list]
					this.editIndex = e.$index
				}
				// 打开模态框
				this.createModel = true
			},
			// 添加类型
			submit() {
				this.$refs.form.validate(res => {
					// 验证属性列表
					var result = true
					var message = [] //存储错误提示消息
					this.value_list.forEach((item, index) => {
						let no = index + 1
						if (item.order == ''){
							result = result && false
							message.push('第' + no + '行：排序不能为空')
						}
						if (item.name == ''){
							result = result && false
							message.push('第' + no + '行：属性名称不能为空')
						}
						if (item.type !== 'input' && item.default == ''){
							
							result = result && false
							message.push('第' + no + '行：属性值不能为空')
						}
					})
					if(!result){
						var temp = '';
						message.forEach(v=>{
							temp += `<li>${v}</li>`
						})
						return this.$notify({
							title: '提示',
							dangerouslyUseHTMLString: true,
							type: 'warning',
							duration:3000, //自动关闭提示
							message: `<ul>${temp}</ul>`
						});
					}
					if (res) {
						let value_list = this.value_list.map(item=>{
							if(item.default){
								item.default = item.default.replace(/\n/g,',')
							}
							return item
						})
						let data = {
							...this.form,
							skus_id:this.skus_id,
							value_list:[...value_list]
						}
						let id = 0
						if (this.editIndex !== -1) {
							id = this.tableData[this.editIndex].id
						}
						// 关闭模态框
						this.addOrEdit(data,id) // 如果id=0就是增加，大于0就是修改
						this.createModel = false
					}

				})
			},
			// 添加属性
			addValue() {
				this.value_list.push({
					order: 50,
					name: "",
					type: "input",
					value: "",
					isedit: false
				})
			},
			// 编辑属性
			editRow(scope) {
				scope.row.isedit = !scope.row.isedit
			},
			// 删除属性值
			delRow(index) {
				this.value_list.splice(index, 1)
			},
			// 添加关联规格
			chooseSkus(){
				this.app.chooseSkus((e)=>{
					let index = this.form.sku_list.findIndex(item=>{
						return item.id === e.id
					})
					if(index === -1){
						this.form.sku_list.push(e)
					}
				})
			},
			// 删除关联规格
			deleteFormSkuList(index){
				this.form.sku_list.splice(index,1)
			}

		}
	}
</script>

<style>
	.sku-list-item>i {
		display: none;
		cursor: pointer;
	}

	.sku-list-item:hover {
		background-color: #F4F4F4;
	}

	.sku-list-item:hover>font {
		display: none;
	}

	.sku-list-item:hover>i {
		display: inline-block;
	}
</style>
